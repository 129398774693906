import { Config } from '@supersonic/config';
import theme from './theme';
import footer from './components/footer';
import { header } from './components/header';
import { blogHeader } from './components/blogHeader';
import holidayGadget from './components/holidayGadget';
import cruiseGadget from './components/cruiseGadget';
import carHireGadget from './components/carHireGadget';
import travelInsurance from './components/travelInsurance';
import { secondaryNav } from './components/secondaryNav';
import crmModal from './components/crmModal';
import crmSignUp from './components/crmSignUp';
import hero from './components/hero';
import expertiseAuthorityTrust from './components/expertiseAuthorityTrust';
import { ASSETS_PREFIX, BLOG_URL_PREFIX } from './constants';

const config: Config = {
  title: 'TravelSupermarket config file',
  name: 'TravelSupermarket',
  shortName: 'TSM',
  titleSuffix: '',
  meta: {},
  domain: process.env.DOMAIN as string,
  assetsPrefix: ASSETS_PREFIX,
  theme,
  pages: {},
  blog: {
    urlPrefix: BLOG_URL_PREFIX,
    archiveStem: 'view-all/',
  },
  heroTakeover: {
    configUrl: process.env.HERO_TAKEOVER_CONFIG_URL as string,
    cookie: process.env.TSM_API_GITHUB_ACCESS_COOKIE as string,
    gadgetStyle: 'horizontal',
    imageCDNUrl: process.env.ITG_CDN_URL as string,
  },
  bestOfSearch: {
    dealsUrl: process.env.BEST_OF_SEARCH_DEALS_URL as string,
    dealsV2Url: process.env.BEST_OF_SEARCH_DEALS_V2_URL as string,
    cookie: process.env.BEST_OF_SEARCH_DEALS_COOKIE as string,
    noResultsLink: '/en-gb/holidays',
    interstitialUrl: '/en-gb/v2/bos/interstitial',
  },
  exposedResultsDeals: {
    dealsUrl: process.env.EXPOSED_RESULTS_DEALS_URL as string,
    cookie: process.env.TSM_API_GITHUB_ACCESS_COOKIE as string,
  },
  carHireDeals: {
    dealsUrl: process.env.CAR_HIRE_DEALS_API_URL as string,
    cookie: process.env.TSM_API_GITHUB_ACCESS_COOKIE as string,
    maxDealsToReturn: 12,
  },
  emailSubscription: {
    emailPreferencesPageUrl: '/en-gb/email-preferences',
    emailPreferencesShowTravelProducts: true,
    emailSubscriptionApi: process.env.EMAIL_SUBSCRIPTION_API as string,
    isEmailSubscribedCookieName: 'travelsupermarket-email-subscribed',
    isEmailPopupShownCookieName: 'travelsupermarket-email-modal-shown',
  },
  holidaysExposedResultsPage: {
    urlStem: '/en-gb/results/holidays',
    dataFolder: process.env.EXPOSED_RESULTS_DATA_FOLDER as string,
  },
  destinationFinder: {
    destinationFinderApi: process.env.DESTINATION_FINDER_API_URL as string,
    urlStem: '/en-gb/holidays/find-your-holiday',
  },
  holidaysResultsPage: {
    brokerApi: process.env.BROKER_API_URL as string,
    advertisingApi: process.env.ADVERTISING_API_URL as string,
    site: 'tsm',
    resultsPerPage: 15,
    title: 'Holiday Results',
    urlStem: '/en-gb/holidays/v2/results',
    interstitialUrl: '/en-gb/holidays/v2/holidays/interstitial',
  },
  holidaysInterstitialPage: {
    logo: 'travelsupermarket',
    image: 'https://img.icelolly.com/interstitial/interstitial-TSM-v2.jpg',
  },
  bestOfSearchInterstitialPage: {
    logo: 'travelsupermarket',
    image: 'https://img.icelolly.com/interstitial/interstitial-TSM-v2.jpg',
  },
  carHireResultsPage: {
    urlStem: process.env.CAR_HIRE_RESULTS_PAGE_URL_STEM as string,
    travelBrokerApi: process.env.TRAVEL_BROKER_API_URL as string,
    resultsApiUrl: process.env.CAR_HIRE_RESULTS_API_URL as string,
    carHireAggregateApiUrl: process.env.CAR_HIRE_AGGREGATE_API_URL as string,
    imageCDNUrl: process.env.ITG_CDN_URL as string,
    interstitialUrl: process.env.CAR_HIRE_INTERSTITIAL_PAGE_URL_STEM as string,
  },
  carHireInterstitialPage: {
    logo: 'travelsupermarket',
    image: `${
      process.env.ITG_CDN_URL as string
    }/car-hire/interstitial/carhire-interstitial-v2.jpg`,
  },
  flightsResultsPage: {
    urlStem: '/en-gb/flights/results',
    gadgetResultsUrl: '/en-gb/flights/results',
    iframeDomain: 'flights-en.travelsupermarket.com',
    title: 'Flights Results - Compare Cheap Flights UK & Worldwide',
    description: '',
  },
  hotelsResultsPage: {
    urlStem: '/en-gb/hotels/results',
    gadgetResultsUrl: '/en-gb/hotels/results',
    iframeDomain: 'flights-en.travelsupermarket.com',
    title: 'Hotels Results',
    description: '',
  },
  topDeals: {
    topDealsApi: process.env.TOP_DEALS_API_URL as string,
    site: 'tsm',
  },
  hotelsInfo: {
    hotelsInfoApiUrl: process.env.HOTELS_INFO_API_URL as string,
  },
  cruise: {
    gadgetSourceId: '134052',
    linkSourceId: '134053',
  },
  components: {
    footer,
    header,
    hero,
    blogHeader,
    holidayGadget,
    cruiseGadget,
    carHireGadget,
    travelInsurance,
    crmModal,
    crmSignUp,
    secondaryNav,
    expertiseAuthorityTrust,
  },
  social: {
    facebook: {
      handle: 'travelsupermarket',
      link: 'https://www.facebook.com/travelsupermarket',
      name: 'Facebook',
    },
    twitter: {
      handle: 'travelsupermkt',
      link: 'https://twitter.com/travelsupermkt',
      name: 'X',
    },
    instagram: {
      handle: 'travelsupermarket',
      link: 'https://www.instagram.com/travelsupermarket/',
      name: 'Instagram',
    },
    youtube: {
      handle: 'travelsupermarket',
      link: 'https://www.youtube.com/user/travelsupermarket',
      name: 'YouTube',
    },
    tiktok: {
      handle: 'travelsupermarket',
      link: 'https://www.tiktok.com/@travelsupermarket',
      name: 'TikTok',
    },
    pinterest: {
      handle: 'travelsupermarket',
      link: 'https://www.pinterest.co.uk/travelsupermarket/',
      name: 'Pinterest',
    },
  },
};

export default config;
