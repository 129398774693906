import { ConfigTheme } from '@supersonic/config';
import ButtonConfig from './components/button';
import ToggleSwitchConfig from './components/toggleSwitch';
import StarRatingConfig from './components/starRating';
import TabsConfig from './components/tabs';
import TooltipConfig from './components/tooltip';
import InstagramGalleryConfig from './components/instagramGallery';
import StickyCtaConfig from './components/stickyCta';
import { checkboxTheme } from './components/checkbox';
import { radioTheme } from './components/radio';
import { lineItemTheme } from './components/lineItem';
import { galleryTheme } from './components/gallery';
import { blogTheme } from './components/blog';
import { linksBlockTheme } from './components/linksBlock';
import { trustYouReviewsTheme } from './components/trustYouReviews';
import { progressBarTheme } from './components/progressBar';
import { budgetHistogramTheme } from './components/budgetHistogram';
import { interstitialTheme } from './components/interstitial';
import { inlineAdTheme } from './components/inlineAd';
import { adOverlayTheme } from './components/adOverlay';
import { headerTheme, navigationTheme } from './components/header';
import { secondaryNavTheme } from './components/secondaryNav';
import { destinationFinderTheme } from './components/destinationFinder';
import { pillTheme } from './components/pill';
import { carHireGadgetTheme } from './components/carHireGadget';
import { holidayResultsTheme } from './components/holidayResults';
import CrmSignUp from './components/crmSignUp';

const theme: ConfigTheme = {
  fontFamily: 'Barlow,AdjustedArialFallback,Arial,sans-serif',
  storybook: {
    title: 'TravelSupermarket',
    right: '🟠',
  },
  spacing: {
    unit: 8,
    _0: '0px',
    _05: '4px',
    _1: '8px',
    _2: '16px',
    _3: '24px',
    _4: '32px',
    _5: '40px',
    _6: '48px',
    _7: '56px',
    _8: '64px',
    _9: '72px',
    _10: '80px',
    _11: '88px',
    _12: '96px',
    _13: '104px',
  },
  fontSizes: {
    alfa: {
      desktop: {
        fontSize: '5rem',
        fontWeight: 600,
        lineHeight: '5.5rem',
      },
      mobile: {
        fontSize: '2.5rem',
        fontWeight: 600,
        lineHeight: '3rem',
      },
    },
    bravo: {
      desktop: {
        fontSize: '2.5rem',
        fontWeight: 600,
        lineHeight: '3rem',
      },
      mobile: {
        fontSize: '2rem',
        fontWeight: 600,
        lineHeight: '2.5rem',
      },
    },
    charlie: {
      desktop: {
        fontSize: '2rem',
        fontWeight: 500,
        lineHeight: '2.5rem',
      },
      mobile: {
        fontSize: '1.5rem',
        fontWeight: 500,
        lineHeight: '2rem',
      },
    },
    delta: {
      desktop: {
        fontSize: '1.5rem',
        fontWeight: 600,
        lineHeight: '2rem',
      },
      mobile: {
        fontSize: '1.125rem',
        fontWeight: 600,
        lineHeight: '1.5rem',
      },
    },
    echo: {
      desktop: {
        fontSize: '1.125rem',
        fontWeight: 600,
        lineHeight: '1.625rem',
      },
      mobile: {
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: '1.5rem',
      },
    },
    foxtrot: {
      desktop: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: '1.5rem',
      },
      mobile: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: '1.5rem',
      },
      boldVariant: {
        fontWeight: 600,
      },
    },
    golf: {
      desktop: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: '1.375rem',
      },
      mobile: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: '1.375rem',
      },
      boldVariant: {
        fontWeight: 600,
      },
    },
    hotel: {
      desktop: {
        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: '1rem',
      },
      mobile: {
        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: '1rem',
      },
      boldVariant: {
        fontWeight: 600,
      },
    },
  },
  colors: {
    black: 'black',
    white: '#fff',
    sand: '#F9F7F6',
    sand_darker1: '#F4F2F1',
    sand_lighter1: '#FDFBFA',
    grey1: '#F5F5F5',
    grey2: '#e9e9e9',
    grey3: '#D9D9D9',
    grey4: '#c4c4c4',
    grey5: '#9d9d9d',
    grey6: '#767676',
    grey7: '#434343',
    grey8: '#212121',
    primary: '#EE6000',
    primary_darker1: '#E54103',
    primary_darker2: '#BE3400',
    primary_darker3: '#AC0042',
    primary_darker4: '#89003D',
    primary_lighter1: '#F18033',
    primary_lighter2: '#F5A066',
    primary_lighter3: '#F8BF99',
    primary_lighter4: '#FCDFCC',
    primary_lighter5: '#FDEFE5',
    primary_lighter6: '#FFEFE4',
    secondary: '#005EBB',
    secondary_darker1: '#004D8E',
    secondary_darker2: '#003D71',
    secondary_darker2_alpha9: 'rgba(0, 61, 113, 0.9)',
    secondary_lighter1: '#3E9CEE',
    secondary_lighter2: '#8DC5F5',
    secondary_lighter3: '#B9DBF9',
    secondary_lighter4: '#E2F1FC',
    secondary_lighter5: '#EEF8FF',
    secondary_lighter6: '#F1FAFF',
    secondary_lighter7: '',
    tertiary: '',
    tertiary_darker1: '',
    tertiary_darker2: '',
    tertiary_lighter1: '',
    tertiary_lighter2: '',
    tertiary_lighter3: '',
    tertiary_lighter4: '',
    tertiary_lighter5: '',
    tertiary_lighter6: '',
    tertiary_lighter7: '',
    supporting1: '#5ACB5A',
    supporting1_darker: '#2AB12F',
    supporting1_lighter: '#D7F1D5',
    supporting2: '#EFBF00',
    supporting2_darker: '#EEA600',
    supporting2_lighter: '#F9E8AD',
    supporting3: '#00B7FF',
    supporting3_darker: '#159BE9',
    supporting3_lighter: '#B2E5FF',
    semantic_success: '#09A57F',
    semantic_success_outline: '#AFDFD0',
    semantic_success_bg: '#D9F8F0',
    semantic_warning: '#FFB400',
    semantic_warning_outline: '#FFE082',
    semantic_warning_bg: '#FFF4D2',
    semantic_error: '#CC0000',
    semantic_error_outline: '#FFCABD',
    semantic_error_bg: '#FFE1DE',
    semantic_info: '#680EDC',
    semantic_info_outline: '#D8C1F3',
    semantic_info_bg: '#E3D9F1',
  },
  cloudflareImagesUrl: process.env.CLOUDFLARE_IMAGES_URL as string,
  accommodationImagesUrl: process.env.ACCOMMODATION_IMAGES_URL as string,
  dates: {
    locale: 'en-GB',
    timezone: 'Europe/London',
  },
  currency: {
    locale: 'en-GB',
    code: 'GBP',
  },
  advertising: {
    adsense: {
      partnerId: '7868497218295224',
      holidaysResultsPageSlotId: '2809604175',
      flightsResultsPageSlotId: '8972500435',
      hotelsResultsPageSlotId: '6058452204',
      destinationFinderPageSlotId: '7460857858',
      carHireResultsPageSlotId: '5898563483',
    },
    gpt: {
      site: 'tsm',
      networkCode: '3132',
      parentAdUnitCode: 'tsm',
      env: process.env.GAM_TARGETING_ENV as string,
      headerBiddingEnabled:
        process.env.HEADER_BIDDING_ENABLED === 'true' ? true : false,
    },
  },
  utmParamsLogic: 'travelsupermarket',
  buttonConfig: ButtonConfig,
  toggleSwitchConfig: ToggleSwitchConfig,
  starRatingConfig: StarRatingConfig,
  instagramGallery: InstagramGalleryConfig,
  stickyCta: StickyCtaConfig,
  tabsConfig: TabsConfig,
  tooltipConfig: TooltipConfig,
  inputField: {
    primaryColor: 'primary',
  },
  checkbox: checkboxTheme,
  radio: radioTheme,
  lineItem: lineItemTheme,
  gallery: galleryTheme,
  blog: blogTheme,
  crmSignUp: CrmSignUp,
  linksBlock: linksBlockTheme,
  trustYouReviews: trustYouReviewsTheme,
  progressBar: progressBarTheme,
  budgetHistogram: budgetHistogramTheme,
  interstitial: interstitialTheme,
  inlineAd: inlineAdTheme,
  adOverlay: adOverlayTheme,
  header: headerTheme,
  navigation: navigationTheme,
  secondaryNav: secondaryNavTheme,
  destinationFinder: destinationFinderTheme,
  pill: pillTheme,
  carHireGadget: carHireGadgetTheme,
  holidayResults: holidayResultsTheme,
};

export default theme;
